/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight";
 */
/* purgecss start ignore */
@tailwind base;
/* purgecss end ignore */
/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/utilities";
 */

@tailwind components;

@layer base {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-semibold;
        @apply text-[#3c475b];
    }

    h1 {
        @apply text-2xl;
    }

    h2 {
        @apply text-xl;
    }

    h3 {
        @apply text-lg;
    }

    h4 {
        @apply text-base;
    }

    h5 {
        @apply text-sm;
    }

    h6 {
        @apply text-xs;
    }
}

@tailwind utilities;

@layer {
    .icon-shadow {
        filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.5));
    }

    .icon-shadow-sm {
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
    }

    .icon-shadow-xs {
        filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.15));
    }

    .text-shadow-sm {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    }

    .text-shadow {
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    }

    .text-shadow-lg {
        text-shadow: 1px 2px 5px #636363;
    }

    .center-align {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .center-y-align {
        position: absolute;
        top: 50%;
        left: auto;
        transform: translateY(-50%);
    }

    .center-x-align {
        position: absolute;
        left: 50%;
        top: auto;
        transform: translateX(-50%);
    }
}

/* Additional CSS Related to tailwind*/
