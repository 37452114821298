/* purgecss start ignore */
@use 'sass:math';

@for $i from 1 through 6 {
    h#{$i} {
        letter-spacing: #{math.div(0.015, $i)}em;
        font-weight: 600;
        color: #3c475b;
    }
}

.gm-ui-hover-effect {
    visibility: hidden;
}

.gm-style .gm-style-iw-c {
    padding: 0 !important;
}

.gm-style-iw-d {
    overflow: hidden !important;
}
.gm-style .gm-style-iw-tc::after {
    background: none;
    box-shadow: none;
}

// styling for PropertySearchResultMap
.advance-search-result {
    min-height: 400px;
}

@-webkit-keyframes bounce {
    0%,
    100% {
        -webkit-transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-15px);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
}

// styling for the dropdown
.property-search-dropdown {
    li.rc-select-dropdown-menu-item-active {
        background-color: #dfe1e1;
        font-weight: 500;
        color: #22292f;
        cursor: pointer;
    }

    li.rc-select-dropdown-menu-item {
        margin-left: 0 0 0 1rem;
        position: relative;
        display: block;
        padding: 1rem 10px;
        color: #666;
        white-space: nowrap;
    }

    &.property-search-dropdown {
        z-index: 1001;
        max-height: 60vh;
        overflow: auto;
    }
}

body .ant-modal-mask {
    filter: opacity(1);
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-filter: opacity(1);
}

/* purgecss end ignore */

/*@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap');*/
@import '~@fortawesome/fontawesome-svg-core/styles.css';

.js-disabled-error {
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    padding: 1rem;
}

.ant-form-explain {
    font-size: 0.8em;
    margin-top: 2px;
    color: #ff8188;
}

.ant-row.ant-form-item.ant-form-item-with-help {
    margin-bottom: 2px;
}

:root .ant-dropdown-trigger .anticon,
:root .ant-dropdown-link .anticon {
    font-size: inherit;
}

#root {
    height: 100%;
    font-size: 14px;
    line-height: 1.5;
}

/*Lazy loading images for imgix*/

.lazyload,
.lazyloading {
    filter: blur(10px);
}

.ls-blur-up-img,
.property-picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    /* only if you want to change the blur-up option from auto to always */
    font-family: 'blur-up: always', 'object-fit: cover';
    object-fit: cover;
}

.ls-blur-up-img {
    filter: blur(10px);
    opacity: 1;
    transition: opacity 1000ms, filter 1500ms;
}

.ls-blur-up-img.ls-inview.ls-original-loaded {
    opacity: 0;
    filter: blur(5px);
}

.text-indent {
    text-indent: 50px;
}

.ant-avatar > img {
    object-fit: cover;
    @apply object-cover object-top;
}

body {
    scroll-behavior: smooth;
    font-family: 'Inter', sans-serif;
}

.backgroundCover {
    position: relative;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
    background-color: rgba(248, 247, 216, 0.7);
}

#snackbar {
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 2;
    left: 50%;
    bottom: 30px;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
}

#snackbar span {
    color: #3b82f6;
    margin-left: 10px;
}

.hero-btn {
    border-radius: 999px;
    padding: 0.5rem 2rem;
    // font-family: 'Poppins';
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.3s ease all;
    display: inline-block;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-width: 2px;
}

.hero-btn:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

.animated-enter {
    opacity: 0;
    max-height: 0;
}

.animated-enter-active {
    opacity: 1;
    max-height: 550px;
    transition: 0.3s cubic-bezier(0, 1, 0.5, 1);
}

.animated-exit {
    opacity: 1;
}

.animated-exit-active {
    opacity: 0;
    max-height: 0;
    transition: 0.3s ease-in-out;
}

.loading-container {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
    height: 32px;
    line-height: 2px;
}

.break-all {
    word-break: break-all;
}

.break-word {
    word-break: break-word;
}

.search-full-page {
    min-height: calc(100vh - 50px - 75px);
    height: 100%;
}

.text-overflow {
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.transition {
    transition: all 0.3s ease-in;
}

.cover-img {
    object-fit: cover;
    width: 100%;
    height: 29vh;
}

img {
    display: inline-block;
}

.anticon {
    vertical-align: 0.1em !important;
}

.bg-realty-blue {
    background-color: #3b82f6;
}

.realty-blue {
    color: #3b82f6;
}

.btn-subscription {
    @apply break-word mb-4 h-auto whitespace-pre-line rounded px-6 py-2 text-base font-normal leading-tight;
}

.btn-branding {
    @apply h-12 border border-pink-600 bg-brnd-pink py-1 text-base font-medium uppercase text-white;
}

.btn-branding:hover {
    @apply border-pink-600 bg-pink-500 text-white;
}

.btn-branding:active,
.btn-branding:focus,
.btn-branding.active {
    @apply border-pink-600 bg-white text-brnd-pink;
}

.btn-branding:focus:hover {
    @apply text-brnd-pink;
}

.btn-branding-ghost {
    @apply h-12 border-brnd-pink py-1 text-base font-medium uppercase text-brnd-pink;
}

/*@media screen and (-webkit-min-device-pixel-ratio:0) {*/
/*    select,*/
/*    textarea,*/
/*    input {*/
/*        font-size: 16px !important;*/
/*    }*/
/*}*/
/*** Styles added to fix the issue with zoom in on iphone ***/
/* iPhone < 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
        font-size: 16px;
    }
}

/* iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
        font-size: 16px;
    }
}

/* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
@media screen and (device-aspect-ratio: 375/667) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
        font-size: 16px;
    }
}

/* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
@media screen and (device-aspect-ratio: 9/16) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
        font-size: 16px;
    }
}

.waitListModalStlye .ant-modal-content {
    border-radius: 15px !important;
}

.existingAgentSearchModalStyle {
    .ant-modal-content {
        border-radius: 15px !important;
    }
    .ant-modal-body {
        padding: 0;
    }
}

.createAgentModalStyle {
    .ant-modal-content {
        border-radius: 8px !important;
    }
    .ant-modal-body {
        padding: 0;
    }
}

/* purgecss end ignore */
