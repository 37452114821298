/* purgecss start ignore */
@use 'sass:math';

@for $i from 1 through 6 {
    h#{$i} {
        letter-spacing: #{math.div(0.015, $i)}em;
        font-weight: 600;
        color: #3c475b;
    }
}

// styling for the dropdown
.property-search-dropdown {
    li.rc-select-dropdown-menu-item-active {
        background-color: #dfe1e1;
        font-weight: 500;
        color: #22292f;
        cursor: pointer;
    }

    li.rc-select-dropdown-menu-item {
        margin-left: 0 0 0 1rem;
        position: relative;
        display: block;
        padding: 1rem 10px;
        color: #666;
        white-space: nowrap;
    }

    &.property-search-dropdown {
        z-index: 1001;
        max-height: 60vh;
        overflow: auto;
    }
}

.customRoundedLgInput {
    .ant-input {
        border-radius: 8px !important;
    }
}

#createAgentAccount {
    .ant-form-item-explain {
        font-size: 12px !important;
    }
}

/* purgecss end ignore */
